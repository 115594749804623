import {Injectable, OnInit} from '@angular/core';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import {adminNavigation, defaultNavigation, horizontalNavigation} from 'app/http/common/navigation/data';
import { cloneDeep } from 'lodash-es';
import {AuthService} from "../../../core/auth/auth.service";
import {User} from "../../../core/user/user.types";

@Injectable({providedIn: 'root'})
export class NavigationMockApi
{
    private _defaultNavigation: FuseNavigationItem[];
    private readonly _horizontalNavigation: FuseNavigationItem[] = horizontalNavigation;

    user: User

    /**
     * Constructor
     */
    constructor(private _fuseMockApiService: FuseMockApiService,
                private _authService: AuthService)
    {
        this._authService.userData$.subscribe(user => {
            this.user = user;
            this.updateNavigationBasedOnUserRole();
        });
        // Register Mock API handlers
        this.registerHandlers();
    }

    private hasAdminAccess(user: User | null): boolean {
        return user?.roles.some(role => role.isSuperAdmin) ?? false;
    }

    private updateNavigationBasedOnUserRole(): void {
        if (!this.user) {
            // Possibly reset to a default state if there's no user
            this._defaultNavigation = defaultNavigation;
        } else {
            this._defaultNavigation = this.hasAdminAccess(this.user) ? adminNavigation : defaultNavigation;
        }
        // You might need to trigger an update in the UI or elsewhere
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void
    {

        // -----------------------------------------------------------------------------------------------------
        // @ Navigation - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/common/navigation')
            .reply(() =>
            {
                this._defaultNavigation = this.hasAdminAccess(this.user) ? adminNavigation : defaultNavigation;
                // Fill horizontal navigation children using the default navigation
                this._horizontalNavigation.forEach((horizontalNavItem) =>
                {
                    this._defaultNavigation.forEach((defaultNavItem) =>
                    {
                        if ( defaultNavItem.id === horizontalNavItem.id )
                        {
                            horizontalNavItem.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                // Return the response
                return [
                    200,
                    {
                        default   : cloneDeep(this._defaultNavigation),
                        horizontal: cloneDeep(this._horizontalNavigation),
                    },
                ];
            });
    }
}
