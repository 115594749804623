import { Injectable } from '@angular/core';
import {Observable, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DrawerTogglerService {
    private drawerToggleSubject = new Subject<void>();

    get toggleDrawer(): Observable<void> {
        return this.drawerToggleSubject.asObservable();
    }

    public toggle(): void {
        this.drawerToggleSubject.next();
    }
  constructor() { }
}
