/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const adminNavigation: FuseNavigationItem[] = [
    {
        id   : 'dashboard',
        title: 'navigation.dashboard',
        type : 'basic',
        icon : 'dashboard_customize',
        link : '/dashboard'
    },
    {
        id   : 'certification',
        title: 'navigation.certification',
        type : 'basic',
        icon : 'heroicons_mini:clipboard-document-check',
        link : '/certification'
    },
    {
        id   : 'users',
        title: 'navigation.users',
        type : 'basic',
        icon : 'heroicons_mini:user-group',
        link : '/users'
    },
    {
        id   : 'companies',
        title: 'navigation.companies',
        type : 'basic',
        icon : 'heroicons_mini:home',
        link : '/companies'
    },
    {
        id   : 'licences',
        title: 'navigation.licences',
        type : 'basic',
        icon : 'heroicons_mini:check-badge',
        link : '/licences'
    },
    {
        id   : 'files',
        title: 'navigation.file-manager',
        type : 'basic',
        icon : 'heroicons_mini:cloud',
        link : '/file-manager'
    },
    {
        id   : 'settings',
        title: 'navigation.settings',
        type : 'collapsable',
        icon : 'heroicons_mini:cog-6-tooth',
        children : [
            {
                id   : 'server.info',
                title: 'navigation.roles-permissions',
                type : 'basic',
                icon : 'heroicons_mini:server-stack',
                link : '/settings/roles-permissions',
            },
            {
                id   : 'smtp.info',
                title: 'navigation.smtp',
                type : 'basic',
                icon : 'heroicons_mini:envelope',
                link : '/settings/smtp',
            },
            {
                id   : 'backup',
                title: 'navigation.backup',
                type : 'basic',
                icon : 'heroicons_mini:circle-stack',
                link : '/settings/backup',
            }
        ]
    }
];

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id   : 'dashboard',
        title: 'navigation.dashboard',
        type : 'basic',
        icon : 'dashboard_customize',
        link : '/dashboard'
    },
    {
        id   : 'certification',
        title: 'navigation.certification',
        type : 'basic',
        icon : 'heroicons_mini:clipboard-document-check',
        link : '/certification'
    },
    {
        id   : 'users',
        title: 'navigation.users',
        type : 'basic',
        icon : 'heroicons_mini:user-group',
        link : '/users'
    },
    {
        id   : 'licences',
        title: 'navigation.licences',
        type : 'basic',
        icon : 'heroicons_mini:check-badge',
        link : '/licences'
    },
    {
        id   : 'files',
        title: 'navigation.file-manager',
        type : 'basic',
        icon : 'heroicons_mini:cloud',
        link : '/file-manager'
    }
];

export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id   : 'dashboard',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/dashboard'
    }
];
