import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {Role, User} from 'app/core/user/user.types';
import { map, Observable, ReplaySubject, tap } from 'rxjs';
import {environment} from "../../../environments/environment";
import {AuthService} from "../auth/auth.service";

@Injectable({providedIn: 'root'})
export class UserService
{
    private BASE_URL = environment.api_url
    private _httpClient = inject(HttpClient);
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);
    private _users: ReplaySubject<User[]> = new ReplaySubject<User[]>();
    private _roles: ReplaySubject<Role[]> = new ReplaySubject<Role[]>();

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    get users$(): Observable<User[]>{
        return this._users.asObservable()
    }

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: User)
    {
        // Store the value
        this._user.next(value);
    }

    get user$(): Observable<User>
    {
        return this._user.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current signed-in user data
     */
    getUsers(): Observable<User[]> {
        return this._httpClient.get<User[]>(`${this.BASE_URL}/api/v1/users/list?status=1`).pipe(
            tap((user: User[]) =>
            {
                this._users.next(user);
            }),
        );
    }

    addUser(user: User) {
        this._httpClient.post<User>(`${this.BASE_URL}/api/v1/users/store`, user).subscribe({
            error: (err) => {
                console.error(err)
            }
        })
    }

    /**
     * Update the user
     *
     * @param uuid
     * @param user
     */
    update(uuid: string, user: any)
    {
        this._httpClient.put<User>(`${this.BASE_URL}/api/v1/account/update/${uuid}`, user).subscribe({
            error: (err) => {
                console.error(err)
            }
        });
    }

    delete(uuid: string){
        this._httpClient.delete(`${this.BASE_URL}/api/v1/users/delete/${uuid}`).subscribe({
            error: (err) => {
                console.error(err)
            }
        })
    }

    forgotPassowrd(email: string){
        this._httpClient.post(`${this.BASE_URL}/api/v1/auth/forgot-password`, {email})
    }

    resetPassword(passwords: any){
        this._httpClient.put(`${this.BASE_URL}/api/v1/auth/reset-password`, passwords)
    }

    setUserValue(){
        const user = localStorage.getItem('userData')
        this._user.next(JSON.parse(user))
    }

    getRoles(): Observable<Role[]>{
        return this._httpClient.get(`${this.BASE_URL}/api/v1/roles/list`).pipe(
            tap((roles: Role[]) => {
                this._roles.next(roles)
            })
        )
    }

    changePassword(passwords: any){
        this._httpClient.put(`${this.BASE_URL}/api/v1/account/change-password`, passwords).subscribe({
            error: err => console.log(err)
        })
    }

    changeLang(lang: any){
        this._httpClient.put(`${this.BASE_URL}/api/v1/account/update/lang`, lang).subscribe({
            next: (value: User) => localStorage.setItem("userData", JSON.stringify(value)),
            error: err => console.log(err)
        })
    }

    changeTheme(theme: any){
        this._httpClient.put(`${this.BASE_URL}/api/v1/account/update/theme`, theme).subscribe({
            next: (value: User) => localStorage.setItem("userData", JSON.stringify(value)),
            error: err => console.log(err)
        })
    }
}
